@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
    .content-empty {
      content: "";
    }
  }
  .triangle {
    @apply content-empty inline-block w-[5px] h-[5px] border-b-2 border-r-2  border-transparent border-black ml-2 rotate-45;
  }
  .triangle1 {
    @apply content-empty inline-block w-[8px] h-[8px] border-b-2 border-r-2  border-transparent border-black ml-2 rotate-45;
  }
  .active {
    @apply relative after:absolute after:content-[''] after:-bottom-2 after:left-0 after:w-[100%] after:h-0.5 after:transform after:scale-x-100 after:bg-white after:origin-bottom-left after:transition-transform after:duration-300 after:ease-out 
  }

@layer component {
    .hamburgerButton.open span:first-child {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        top: 9px;      
    }
    .hamburgerButton.open span:nth-child(2) {
      opacity: 0;
    }
    .hamburgerButton.open span:last-child {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        top: -7px;
    }

    }
    .show{
      opacity: 1;
      max-height: 500px; 
      transition: opacity 0.5s, max-height 0.5s;
      overflow: scroll;
    }
    .hide{
      opacity: 0;
      max-height: 0;
      transition: opacity 0.5s, max-height 0.5s;
      overflow: hidden;
    }
    
    
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Libre+Baskerville&display=swap');

@font-face {
  font-family: 'Aroly';
  src: url('./Font/AROLY.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'OrkneyBold';
  src: url('./Font/Orkney/Orkney\ Bold.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'OrkneyRegular';
  src: url('./Font/Orkney/Orkney\ Regular.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'OrkneyLight';
  src: url('./Font/Orkney/Orkney\ Light.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Thinoo';
  src: url('./Font/Thinoo/thinoobold.otf') format('truetype');
  font-weight: normal;
}

